import { safeFetch } from '../utils';
import {
  CategoriesType,
  Event,
  IPagination,
  IPaginationResponse,
  ISearchEventFilterParams,
  EventSeason,
  Season
} from '@gf/cross-platform-lib/interfaces';
import {
  DEFAULT_PAGINATION_RESPONSE,
  GetCurrentApplicationConfiguration,
  Method,
  parseQueryParamsToStringV2
} from '@gf/cross-platform-lib/utils';
import isEmpty from 'lodash/isEmpty';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

const appConfig = GetCurrentApplicationConfiguration();

export interface ResponseOptions {
  showLayoutOnError?: boolean;
  redirect404?: boolean;
}

export async function searchEvents(queryParams: ISearchEventFilterParams, pagingParams: IPagination) {
  let allParams: ISearchEventFilterParams = { redeemableOnly: true };

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value !== undefined && key !== 'schoolId') {
      if (Array.isArray(value)) {
        (allParams as any)[key] = value.join(',');
      } else {
        (allParams as any)[key] = value;
      }
    }
  });
  if (queryParams.levels) {
    allParams.levels = queryParams.levels;
  }
  const url = `${appConfig.api.events.baseUrl}/events/search/paginated/${queryParams.schoolId}/${
    pagingParams?.page || 0
  }${!isEmpty(allParams) ? parseQueryParamsToStringV2(allParams) : ''}`;
  return safeFetch<IPaginationResponse<Event>>(url);
}

export async function searchSeasons(queryParams: ISearchEventFilterParams, pagingParams: IPagination) {
  const hasPostSeason = queryParams?.postSeason !== undefined;
  const hasSelectedSchools = queryParams?.selectedSchools !== undefined;

  if (hasPostSeason || hasSelectedSchools) {
    return Promise.resolve({
      status: 200,
      data: DEFAULT_PAGINATION_RESPONSE,
      error: null
    });
  }

  let allParams: ISearchEventFilterParams = {};

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value !== undefined && key !== 'schoolId') {
      if (Array.isArray(value)) {
        (allParams as any)[key] = value.join(',');
      } else {
        (allParams as any)[key] = value;
      }
    }
  });
  if (queryParams.levels) {
    allParams.levels = queryParams.levels;
  }
  const url = `${appConfig.api.events.baseUrl}/seasons/search/paginated/${queryParams.schoolId}/${
    pagingParams?.page || 0
  }${!isEmpty(allParams) ? parseQueryParamsToStringV2(allParams) : ''}`;

  return safeFetch<IPaginationResponse<Season>>(url);
}

export const getAllEventCategories = async (schoolId = '') => {
  const url = `${appConfig.api.events.baseUrl}/events/categories/${schoolId}`;
  return safeFetch<CategoriesType>(url);
};

export const getAllSeasonCategories = async (schoolId = '') => {
  const url = `${appConfig.api.events.baseUrl}/seasons/categories/${schoolId}`;
  return safeFetch<CategoriesType>(url);
};

export const getEvent = async (eventId: string, responseOptions: ResponseOptions) => {
  if (!eventId) {
    const message = 'No event ID provided to getEvent';
    recordError(message, {
      originatingFunction: 'eventApis-getEvent',
      customMessage: 'Attempted to fetch event details with a missing event ID.',
      errorGroup: NEW_RELIC_ERROR_GROUPS.DataIssues,
      expected: true
    });
    throw new Error(message);
  }
  const url = `${appConfig.api.events.baseUrl}/events/${eventId}`;
  return safeFetch<Event>(url, {}, responseOptions);
};

export const getNFHSEventByGameKey = async (gameKey: string) => {
  if (!gameKey) {
    const error = new Error('GameKey is required to fetch NFHS event information');
    recordError(error, {
      errorGroup: NEW_RELIC_ERROR_GROUPS.APIRequests,
      errorContext: 'Event Lookup',
      originatingFunction: 'getNFHSEventByGameKey'
    });
    throw error;
  }

  const url = `${appConfig.api.searchapi.baseUrl}/v2/search/events?key[]=${gameKey}`;

  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    });

    if (!response.ok) {
      recordError(new Error(`NFHS API response not OK: ${response.statusText}`), {
        errorGroup: NEW_RELIC_ERROR_GROUPS.APIRequests,
        errorContext: 'Event Lookup',
        originatingFunction: 'getNFHSEventByGameKey',
        data: { gameKey, statusCode: response.status }
      });
      console.error('Response not OK:', response.statusText);
      return { siteUrl: undefined, eventData: undefined };
    }

    const responseData = await response.json();
    const event = responseData?.items?.[0];

    if (!event) {
      recordError(new Error('No event found in NFHS API response'), {
        errorGroup: NEW_RELIC_ERROR_GROUPS.APIRequests,
        errorContext: 'Event Lookup',
        originatingFunction: 'getNFHSEventByGameKey',
        data: { gameKey }
      });
      return { siteUrl: undefined, eventData: undefined };
    }

    return {
      siteUrl: event.site_url,
      eventData: event
    };
  } catch (error) {
    recordError(error instanceof Error ? error : new Error(String(error)), {
      errorGroup: NEW_RELIC_ERROR_GROUPS.APIRequests,
      errorContext: 'Event Lookup',
      originatingFunction: 'getNFHSEventByGameKey',
      data: { gameKey }
    });
    throw error;
  }
};

export const getSeason = async (seasonId: string, responseOptions: ResponseOptions) => {
  const url = appConfig.api.seasons.getUrlForMethodAndId!(Method.GET, seasonId);
  return safeFetch<EventSeason>(url!, {}, responseOptions);
};
